//获取题目列表
export const getQuestionList =
  "/gateway/hc-low-carbon/manageapi/garbage/questionList";
//题干增加
export const postStemAddOrEdit =
  "/gateway/hc-low-carbon/manageapi/garbage/stemAddOrEdit";
//题干删除
export const stemDeletion =
  "/gateway/hc-low-carbon/manageapi/garbage/stemDelete";
//题干详情
export const stemDetails = "/gateway/hc-low-carbon/manageapi/garbage/stemInfo";
//题目列表
export const questionListURL = `/gateway/hc-govern/manageapi/qu/questionList`;
//题干批量增加|编辑
export const stemAddBatchURL = `/gateway/hc-govern/manageapi/qu/stemAddBatch`;
