/*
 * @Author: lzj
 * @Date: 2022/2/15 10:21
 * @LastEditors: lzj
 * @LastEditTime: 2022/2/15 10:21
 * @Description: ...
 */
import { vue } from "@/main";
import { createImgVNode } from "@/utils/utils";

export const questionStatusList = [
  { value: 1, label: "上架" },
  { value: 2, label: "下架" },
];

const shelfSts = {
  0: "待上架",
  1: "上架",
  2: "下架",
};
export const questionHeaders = [
  {
    prop: "imageUrls",
    label: "图片",
    align: "center",
    formatter: (row, prop) => createImgVNode(vue, row, prop),
  },
  {
    prop: "title",
    align: "center",
    label: "答题题目",
  },
  {
    prop: "shelfSts",
    label: "发布状态",
    align: "center",
    formatter: (row) => {
      return shelfSts[row.shelfSts];
    },
  },
];
