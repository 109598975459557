<template>
  <div class="question">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :searchParam="searchParam"
      :table-url="tableUrl"
    >
      <template #headBtnSlot>
        <v-button text="新增" @click="toAdd"></v-button>
      </template>
      <template #searchSlot>
        <v-input
          label="搜索"
          v-model="searchParam.title"
          @change="$refs.list.search()"
        />
        <v-select
          clearable
          :options="questionStatusList"
          v-model="searchParam.shelfSts"
          @change="$refs.list.search()"
          label="发布状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          v-if="scope.row.canEdit == undefined || scope.row.canEdit"
          type="text"
          @click="toEdit(scope.row)"
        />
        <v-button
          text="删除"
          type="text"
          @click="questionDelete(scope.row.id)"
        />
      </template>
    </v-list>
  </div>
</template>

<script>
import {
  questionHeaders,
  questionStatusList,
} from "@/views/rubbishSort/question/questionConfig";
import {
  getQuestionList,
  stemDeletion,
} from "@/views/rubbishSort/question/api";

export default {
  data() {
    return {
      searchParam: {
        title: null,
        shelfSts: null,
      },
      questionStatusList: questionStatusList,
      headers: questionHeaders,
      tableUrl: getQuestionList,
    };
  },
  methods: {
    toAdd() {
      this.$router.push({ name: "editRubQuestion" });
    },
    toEdit(data) {
      this.$router.push({
        name: "editRubQuestion",
        query: { id: data.id, quType: data.quType },
      });
    },
    questionDelete(id) {
      this.$confirm("是否确定操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const params = {
          stemId: id,
        };
        this.$axios({
          method: "post",
          url: stemDeletion,
          params: params,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("操作成功");
            this.$refs.list.search();
          }
        });
      });
    },
  },
};
</script>
<style lang="less" scoped>
.question {
  box-sizing: border-box;
  height: 100%;
}
///deep/ .el-table_1_column_4 {
//  text-align: center;
//  .opera-btn {
//    display: flex;
//    align-items: center;
//    justify-content: center;
//  }
//}
</style>
